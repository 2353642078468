import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ['practiceArea']

  connect(){
    this.practiceAreaTargets[0].parentElement.classList.add('active')
  }

  filter(e) {
    e.preventDefault();
    const namespaceValue = this.element.getAttribute('namespace-value');
    let cardLink;
    let currentPracticeArea = e.currentTarget; 
    let slugValue = currentPracticeArea.getAttribute('value')
    this.activateButton(e);

    fetch(`/${namespaceValue}/experfy-talentclouds?practice_area=${slugValue}`, {
      headers: { accept: 'application/json'
    }})
    .then((response) => response.json())
    .then(data => {
      let cloudHTML = "";
      const cloudArray = Object.values(data)[0];
      cloudArray.forEach(cloud => {
        if (namespaceValue == "experts")
          cardLink = `/jobs/${slugValue}/${cloud.slug}`;
        else
          cardLink = `/hire/${slugValue}/${cloud.slug}`;
        cloudHTML += this.cloudTemplate(cloud, cardLink, this.stripHtml(cloud.description)); 
      });
      document.getElementById('clouds-results').innerHTML = cloudHTML;
      var currentPA = Object.values(data)[2];
      var practice_area_link = this.get_p_a_link(namespaceValue, currentPA);
      document.getElementById('practice_area_button').innerHTML =  this.currentPracticeArea(practice_area_link, currentPA);
    });
  }

  get_p_a_link(namespaceValue, practice_area){
    if (namespaceValue == "experts")
      return `/jobs/${practice_area.slug}#filters`
    else
      return `/hire/${practice_area.slug}#filters`
  }

  activateButton(e){
    this.practiceAreaTargets.forEach(P => {
      P.parentElement.classList.remove('active');
    });
    e.currentTarget.parentElement.classList.add('active');
  }

  currentPracticeArea(practice_area_link, practice_area){
    return `<a class="btn btn--lg btn--outline btn--outline-mixed-alternate" href="${practice_area_link}">${practice_area.title}</a>`
  }

  stripHtml(description){
    return description.replace(/<(.|\n)*?>/g, '');
  }

  cloudTemplate(cloud, cardLink, desc) {
    return `<div class='col col--4'>
              <a href="${cardLink}">
                <div class='card-wrapper'>                
                  <div class='card card-talentcloud mb--1-5'>
                    <div class='card-talentcloud__header'>
                      <h4 class='card-talentcloud__title'>${cloud.title}</h4>
                    </div>
                    <div class='card-talentcloud__body'>
                      <div class='card-talentcloud__body__content'>
                        ${desc.substring(0,238)}...
                      </div>  
                    </div>
                    <div class='card-talentcloud__footer'>
                      <button class='btn btn--primary btn--sm'>
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>`
  }
}

