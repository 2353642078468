import { Controller } from 'stimulus';
import debounced from 'debounced';

export default class extends Controller {
  static targets = [ 'count', 'select' ]

  connect() {
    debounced.initialize({ input: { wait: 300 } })
  }

  changeResourcesNumber() {
    let resourcesNumber = parseInt(this.countTarget.value);
    if (resourcesNumber > 50) {
      this.countTarget.value = 50;
      resourcesNumber = 50;
    }
    const selectCount = this.selectTargets.length;
    const container = document.getElementById('skill-levels');
    if (resourcesNumber > 50) {
      this.loopFunction(50, container);
    } else if( resourcesNumber > selectCount ) {
      let step = (resourcesNumber - selectCount);
      this.loopFunction(step, container);
    } else {
      for (let step = 0; step < (selectCount - resourcesNumber); step++) {
        if(container.children.length > 2)
          container.children[container.children.length - 1].remove()
      }
    }
  }

  connectSelect() {
    this.selectController.connect()
  }

  get selectController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "select")
  }

  loopFunction(counter, container) {
    for (let step = 0; step < counter; step++) {
      const template = document.getElementById('skill-level-template');
      const selectLevels = template.content.cloneNode(true);
      selectLevels.lastElementChild.setAttribute('id', `skill-level-${step}`);

      container.appendChild(selectLevels);
      if (step === counter - 1) {
        this.connectSelect();
      }
    }
  }
}
