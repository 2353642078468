import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'container' ]

  connect() {
    this.containerDistance = window.pageYOffset + this.containerTarget.getBoundingClientRect().top - 54
  }

  scroll() {
    if (window.pageYOffset > this.containerDistance) {
      this.containerTarget.classList.add('is-fixed');
    } else {
      this.containerTarget.classList.remove('is-fixed');
    }                                       
  }
}