import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'modalSuccess'];

  open(event) {
    event.stopPropagation();
    document.body.classList.add('modal-open');
    this.modalTarget.classList.add('show');
  }

  openSuccess(event) {
    event.stopPropagation();
    document.body.classList.add('modal-open');
    this.modalSuccessTarget.classList.add('show');
  }

  close() {
    document.body.classList.remove('modal-open');
    this.modalTarget.classList.remove('show');
  }

  closeSuccess() {
    document.body.classList.remove('modal-open');
    this.modalSuccessTarget.classList.remove('show');
  }
}  