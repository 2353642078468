import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  scroll() {
    var body = document.body,
        html = document.documentElement

    var height = Math.max(body.scrollHeight, 
                          body.offsetHeight, 
                          html.clientHeight,
                          html.scrollHeight,
                          html.offsetHeight)
                          
    if (window.pageYOffset >= height - window.innerHeight) {
      this.containerTarget.classList.add('is-visible');
    }                      
  }

  close(e) {
    if (e && this.preventDefaultActionClosing) {
      e.preventDefault();
    }

    this.containerTarget.classList.remove('is-visible');
  }
} 
  