import { Controller } from "stimulus"
const Choices = require('choices.js')

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    for( var input of this.inputTargets ) {
      input.choices = new Choices(input, {
        shouldSort: false,
        callbackOnInit: function() {
          window.choiceSelects = window.choiceSelects || [];
          const reference = { id: input.id, choice: this };
          window.choiceSelects.push(reference);
        }
      });
    }
  }

  disconnect() {
    for( var input of this.inputTargets ) {
      const reference = window.choiceSelects.find(ref => ref.id == input.id);
      const index = window.choiceSelects.indexOf(reference);
      if(index !== -1) {
        window.choiceSelects.splice(index, 1);
      }
      input.choices.destroy();
    }
  }
}
