import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["search"]

  pageNumber = 1;

  search(e) {
    const namespaceValue = this.element.getAttribute('data-search-clouds-namespace-value');
    if (e.which == '13') {
      e.preventDefault();
      let cardLink;
      const value = this.searchTarget.value;      

      fetch(`/${namespaceValue}/experfy-talentclouds?search_experfy_clouds=${value}`, {
        headers: { accept: 'application/json'
      }})
      .then((response) => response.json())
      .then(data => {
        let cloudHTML = "";
        const cloudArray = Object.values(data)[0];
        cloudArray.forEach(cloud => {
          cardLink = `/${namespaceValue}/experfy-talentclouds/${cloud.id}`;
          cloudHTML += this.cloudTemplate(cloud, cardLink, this.stripHtml(cloud.description)); 
        });
        document.getElementById('clouds-results').innerHTML = cloudHTML;
      });
    }
  }
  
  stripHtml(description){
    return description.replace(/<(.|\n)*?>/g, '');
  }

  cloudTemplate(cloud, cardLink, desc) {
    return `<div class='col col--4'>
              <a href="${cardLink}">
                <div class='card-wrapper'>                
                  <div class='card card-talentcloud mb--1-5'>
                    <div class='card-talentcloud__header'>
                      <h4 class='card-talentcloud__title'>${cloud.title}</h4>
                    </div>
                    <div class='card-talentcloud__body'>
                      <div class='card-talentcloud__body__content'>
                        ${desc.substring(0,238)}...
                      </div>  
                    </div>
                    <div class='card-talentcloud__footer'>
                      <button class='btn btn--primary btn--sm'>
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </a>
            </div>`
  }
}