// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require('@rails/activestorage').start()
require('channels')

// import { Application } from "stimulus"
// import { definitionsFromContext } from "stimulus/webpack-helpers"
import 'controllers'

// const application = Application.start()
// const context = require.context("../controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

import flatpickr from 'flatpickr'
require('flatpickr/dist/flatpickr.css')
require('scroll')

document.addEventListener('turbolinks:load', () => {
  flatpickr("[data-behavier='flatpickr']", {
    altInput: true,
    altFormat: 'm/d/Y',
    dateFormat: 'Y-m-d',
    monthSelectorType: 'static',
    onOpen: () => {
      showCurrentMonthDaysOnly()
    },
    onMonthChange: () => {
      showCurrentMonthDaysOnly()
    },
    onYearChange: () => {
      showCurrentMonthDaysOnly()
    },
  })

  const availability_toggle = document.querySelector('#profile_work_available');

  if(availability_toggle != null)
    availability_toggle.onchange = () => {
      document.querySelector('.availability_date_wrapper').classList.toggle('is-hidden');
    }

  function showCurrentMonthDaysOnly() {
    document.querySelectorAll('.prevMonthDay').forEach(
      (el) => {
        el.innerHTML = '';
        el.classList.add('flatpickr-disabled')
      }
    )

    document.querySelectorAll('.nextMonthDay').forEach(
      (el) => {
        el.innerHTML = '';
        el.classList.add('flatpickr-disabled')
      }
    )
  }
})

document.addEventListener('turbolinks:request-start', function (event) {
  var xhr = event.data.xhr;
  xhr.setRequestHeader(
    'X-Turbolinks-Nonce',
    document.querySelector("meta[name='csp-nonce']").getAttribute('content')
  );
});