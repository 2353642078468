import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['card', 'trash', 'input']

  add(event) {
    let questionType = event.target.dataset['questionType']
    let singular = event.target.dataset['singular']

    // Check if question is already present on page
    if(singular && this.cardTargets.find(el => el.dataset['questionType'] == questionType))
      return

    const container = document.getElementById('questions-list')
    const template = document.getElementById(questionType)
    const question = template.content.cloneNode(true)

    let stamp = Date.now()

    // Change `id`, `for` and `name` attributes to make radio groups in scope of one question
    let reqTrue = question.firstElementChild.getElementsByClassName('is_required_true')
    reqTrue[0].setAttribute('id', `is_required_true_${stamp}`)
    reqTrue[0].setAttribute('name', `is_required_${stamp}`)
    reqTrue[1].setAttribute('for', `is_required_true_${stamp}`)

    let reqFalse = question.firstElementChild.getElementsByClassName('is_required_false')
    reqFalse[0].setAttribute('id', `is_required_false_${stamp}`)
    reqFalse[0].setAttribute('name', `is_required_${stamp}`)
    reqFalse[1].setAttribute('for', `is_required_false_${stamp}`)

    container.appendChild(question);
  }

  remove(event){
    event.preventDefault()
    if(this.hasCardTarget)
      this.cardTarget.parentNode.remove()
  }

  changeRequired(event) {
    this.inputTarget.setAttribute('value', event.currentTarget.value)
  }
}
