import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'modal' ];

  open(event) {
    event.preventDefault()
    document.body.classList.add('modal-open');
    this.modalTarget.classList.add('show');
  }

  close() {
    document.body.classList.remove('modal-open');
    this.modalTarget.classList.remove('show');
  }
}  