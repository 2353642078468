import { Controller } from "stimulus"
const Choices = require('choices.js')

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    let highlightedItemId = "";
    this.inputTarget.choices = new Choices(this.inputTarget, {
      removeItemButton: true,
      renderChoiceLimit: 1,
      shouldSort: false,
      duplicateItemsAllowed: false,
      searchFields: ['label'],
      choices: [{
        value: '',
        label: 'Enter tags manually or use Recommendations',
        selected: false,
        disabled: true,
      }],
      fuseOptions: {
        threshold: '0.0'
      },
    });

    this.inputTarget.addEventListener(
      'addItem',
      (event) => {
        let checkbox = document.querySelector(`[value*="${event.detail.value}"]`)

        if(checkbox != undefined) {
          let parent = checkbox.dataset.parent

          if(parent != undefined) {
            document.querySelector(`[data-id*="${parent}"]`).checked = true
          }
          checkbox.checked = true
        }
      },
      false,
    );

    this.inputTarget.addEventListener(
      'removeItem',
      (event) => {
        let checkbox = document.querySelector(`[value*="${event.detail.value}"]`)

        if(checkbox != undefined) {
          let parent = checkbox.dataset.parent

          if(document.querySelectorAll(`[data-parent*="${parent}"]:checked`).length == 1) {
            document.querySelector(`[data-id*="${parent}"]`).checked = false
          }
          checkbox.checked = false
        }
      },
      false,
    );

    this.inputTarget.addEventListener(
      'highlightChoice',
      function(event) {
        this.highlightedItemId = event.detail.el.id;
      },
      highlightedItemId,
    );

    this.inputTarget.addEventListener(
      'search',
      function(event) {
        if (this.highlightedItemId) {
          let highlightedItem = document.getElementById(`${this.highlightedItemId}`);
          if (highlightedItem) {
            highlightedItem.classList.add('is-highlighted');
          }
        } 
      },
      highlightedItemId,
    );
  }

  disconnect() {
    this.inputTarget.choices.destroy();
  }
}
