import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

// copy tinymce's skins directory into public/packs
require.context(
  '!file-loader?name=[path][name].[ext]&context=node_modules/tinymce&outputPath=js!tinymce/skins',
  true,
  /.*/
);

export default class extends Controller {
  static targets = [ 'textarea' ]

  connect() {
    tinymce.init({
      selector: '.tinymce',
      menubar: false,
      statusbar: false,
      plugins: ['link lists paste'],
      toolbar: ['numlist bullist alignleft aligncenter link bold italic underline'],
      branding: false,
      inline_styles: false,
      height: '180',
      placeholder: this.data.get('placeholder'),
      content_style: '.mce-content-body[data-mce-placeholder] { cursor: text; color: #a1a5ad; }' + 
                     '.mce-content-body { font-family: "Proxima Nova", sans-serif; color: #4a5162; line-height: 1.325;}',
    });
  }
}
