import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'range', 'input', 'wrapper' ]

  changeInput() {
    const rangeValue = this.rangeTarget.value;
    this.inputTarget.value = rangeValue;
    this.showProgress(rangeValue);
  }

  changeRange() {
    const inputValue = this.inputTarget.value;
    const parsedValue = parseInt(inputValue);
    if (parsedValue >= 0) {
      this.rangeTarget.value = inputValue;
      this.showProgress(inputValue);
    } else {
      this.inputTarget.value = null;
    }
    this.rangeTarget.value = inputValue;
    this.showProgress(inputValue);
  }

  showProgress(value) {
    this.wrapperTarget.style.setProperty('--value', value);
  }
}