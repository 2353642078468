import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']

  close(event) {
    event.preventDefault()
    this.containerTarget.remove()
  }  

  closeWithRedirect(event) {
    event.preventDefault()
    var redirectURL = event.currentTarget.dataset.url
    this.containerTarget.remove()
    window.location.href = redirectURL
  }
}