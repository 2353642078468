import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  connect() {
    this.inputTargets.forEach(
      (input) => {
        input.flatpickr = flatpickr(input, {
          altInput: true,
          altFormat: 'm/d/Y',
          dateFormat: 'Y-m-d',
          monthSelectorType: 'static',
          minDate: 'today',
          onOpen: () => {
            this.showCurrentMonthDaysOnly()
          },
          onMonthChange: () => {
            this.showCurrentMonthDaysOnly()
          },
          onYearChange: () => {
            this.showCurrentMonthDaysOnly()
          },
          onChange: (selectedDates, dateStr, instance) => {
            if(instance.element == this.inputTarget)
              this.inputTargets[1].flatpickr.set('minDate', dateStr)
          }
        })
      }
    )
  }

  showCurrentMonthDaysOnly() {
    document.querySelectorAll('.prevMonthDay').forEach(
      (el) => {
        el.innerHTML = '';
        el.classList.add('flatpickr-disabled')
      }
    )

    document.querySelectorAll('.nextMonthDay').forEach(
      (el) => {
        el.innerHTML = '';
        el.classList.add('flatpickr-disabled')
      }
    )
  }
}
