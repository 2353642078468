import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'checkbox', 'container', 'list' ];

  filter(event) {
    let query = event.currentTarget.value.toLowerCase()

    if(query.length > 0) {
      this.listTargets.forEach((list) => {
        list.classList.add('search-result')
      })

      this.containerTargets.forEach((container) => {
        container.classList.add('hidden')
      })

      this.checkboxTargets.forEach((checkbox) => {
        if(checkbox.name.toLowerCase().includes(query)) {
          checkbox.parentNode.parentNode.classList.remove('hidden')
        }
      })

    } else {
      this.listTargets.forEach((list) => {
        list.classList.remove('search-result')
      })

      this.containerTargets.forEach((container) => {
        container.classList.remove('hidden')
      })
    }
  }
}
