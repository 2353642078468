import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'modal', 'checkbox', 'choice', 'container', 'list', 'search' ]

  open(event) {
    event.preventDefault()
    this.resetModal()
    document.body.classList.add('modal-open')
    this.modalTarget.classList.add('show')
  }

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.add('hide')
    this.modalTarget.classList.remove('show')
    document.body.classList.remove('modal-open')
    setTimeout(() => {
      this.modalTarget.classList.remove('hide')
    }, 499)
  }

  check(event) {
    let checkbox = event.currentTarget

    if(!checkbox.dataset.target.includes('recommendation')) {
      checkbox.dataset.target += ' recommendation-modal.checkbox'
      checkbox.dataset.original = !checkbox.checked
    }
  }

  checkChildren(event) {
    let id = event.currentTarget.dataset.id
    let children = document.querySelectorAll(`[data-parent*="${id}"]`)

    children.forEach((checkbox)=> {
      checkbox.checked = event.currentTarget.checked
      if(!checkbox.dataset.target.includes('recommendation')){
        checkbox.dataset.target += ' recommendation-modal.checkbox'
        checkbox.dataset.original = !event.currentTarget.checked
      }
    })
  }

  checkParent(event) {
    let id = event.currentTarget.dataset.parent
    let checked = document.querySelectorAll(`[data-parent*="${id}"]:checked`).length > 0

    document.querySelector(`[data-id*="${id}"]`).checked = checked
  }

  submit(event) {
    event.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      let value = checkbox.value
      if(checkbox.checked)
        this.choiceTarget.choices.setChoiceByValue(value)
      else
        this.choiceTarget.choices.removeActiveItemsByValue(value)

      checkbox.dataset.target = checkbox.dataset.target.replace(' recommendation-modal.checkbox', '')
    })

    this.close(event)
  }

  resetModal() {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = (checkbox.dataset.original === 'true')
      let parent = checkbox.dataset.parent

      if(parent == undefined)
        return

      if(checkbox.checked) {
        document.querySelector(`[data-id*="${parent}"]`).checked = true
      } else if(document.querySelectorAll(`[data-parent*="${parent}"]:checked`).length == 0) {
        document.querySelector(`[data-id*="${parent}"]`).checked = false
      }

      checkbox.dataset.target = checkbox.dataset.target.replace(' recommendation-modal.checkbox', '')
    })

    this.searchTargets.forEach((search) => {
      search.value = ''
    })

    this.listTargets.forEach((list) => {
      list.classList.remove('search-result')
    })

    this.containerTargets.forEach((container) => {
      container.classList.remove('hidden')
    })
  }
}
