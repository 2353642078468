import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce';

export default class extends Controller {
  static targets = [ 'indicator', 'step', 'progress', 'error', 'errorContainer' ]

  nextStep(event) {
    this.showStep(event, 'forward')
  }

  previousStep(event) {
    this.showStep(event, 'backward')
  }

  showStep(event, direction) {
    event.preventDefault()
    let stepId = event.target.dataset.id
    let currentId = event.target.dataset.current
    let inputs = document.getElementById(currentId).querySelectorAll('.form-input--required:enabled')

    if (direction == 'forward') {
      if (inputs.length > 0) {
        for (let i = 0; i < inputs.length; i++) {
          let item = inputs[i]

          if (item.classList.contains('tinymce')) {
            let textContent = tinymce.get(item.id).getContent();
            if (textContent == null || textContent == "") {
              item.parentNode.classList.add('is-required')
            } else {
              item.parentNode.classList.remove('is-required')
            }
          } else {
            if (item.value == null || item.value == "" || item.value == "0") {
              item.parentNode.classList.add('is-required')
            } else {
              item.parentNode.classList.remove('is-required')
            }
          }
        }

        if (parseInt(currentId) === 2) {
          let selectedPanel = document.querySelector('.form-radio-toggle').dataset.radioToggleIndex;
          if (parseInt(selectedPanel) === 1) {
            let minRateInput = document.querySelector('.min-rate-value');
            let maxRateInput = document.querySelector('.max-rate-value');
            if (parseInt(minRateInput.value) > parseInt(maxRateInput.value)) {
              minRateInput.classList.add('is-required');
              maxRateInput.classList.add('is-required');
            } else {
              minRateInput.classList.remove('is-required');
              maxRateInput.classList.remove('is-required');
            }
          } else {
            let timeCommitment = document.querySelector('.time-commitment-value');
            if (parseInt(timeCommitment.value) > 60) {
              timeCommitment.classList.add('is-required');
            } else {
              timeCommitment.classList.remove('is-required');
            }
          }
        }

        let empty_inputs = document.getElementById(currentId).querySelectorAll('.is-required')

        if (empty_inputs.length == 0) {
          this.switchClass(stepId)
          this.progressTarget.classList.add("value--" + stepId)
          this.progressTarget.classList.add("value--" + currentId)
          document.getElementById("indicator_" + stepId).classList.add('is-active')
          this.clearModelErrors(stepId)
        }
      } else {
        this.switchClass(stepId)
        this.progressTarget.classList.add("value--" + stepId)
        this.progressTarget.classList.add("value--" + currentId)
        document.getElementById("indicator_" + stepId).classList.add('is-active')
      }
    } else {
      this.switchClass(stepId)
      let originId = parseInt(stepId) + 1
      document.getElementById("indicator_" + String(originId)).classList.remove('is-active')
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  switchClass(id) {
    this.stepTargets.forEach((step) => {
      step.classList.add('is-hidden')
    })
    document.getElementById(id).classList.remove('is-hidden')
  }

  clearModelErrors(stepId) {
    if(this.errorContainerTargets.length === 0 )
      return

    this.errorTargets.forEach((error) => {
      if(error.dataset.stepId === stepId)
        error.remove()
    })

    if(this.errorTargets.length === 0)
      this.errorContainerTarget.remove()
  }
}
