import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'fieldset']

  connect() {
    if (this.checkboxTarget.checked == true) {
      this.show();
    }
  }

  show() {
    this.fieldsetTarget.classList.toggle('is-hidden');
  }  
}