import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "state", "select" ]

  validate() {
    const reference = window.choiceSelects.find(ref => ref.id == this.stateTarget.id);

    if(this.selectTarget.value === "US") {
      reference.choice.enable();
    } else {
      reference.choice.disable();
      reference.choice.setChoiceByValue("");
    }
  }

  connect() {
    window.onload = () => {
      const reference = window.choiceSelects.find(ref => ref.id == this.stateTarget.id);
      if(this.selectTarget.value !== "US") {
        reference.choice.disable();
      }
    }
  }
}
