import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'menu']

  // connect() {
    
  // }

  // toggle(event) {
  //   event.stopPropagation()
  //   event.preventDefault()

  //   if (this.buttonTarget.getAttribute('aria-expanded') == "false") {
  //     this.show()
  //   } else {
  //     this.hide(event)
  //   }
  // }

  // show() {
  //   this.buttonTarget.setAttribute('aria-expanded', "true");
  //   this.menuTarget.classList.remove('hidden');
  // }

  // hide(event) {
  //   if (event.target == this.menuTarget) {
  //     event.preventDefault()
  //     return
  //   }
  //   this.buttonTarget.setAttribute('aria-expanded', "false")
  //   this.menuTarget.classList.add('hidden');
  // }

  connect() {
    this.buttonToggleClass = this.data.get('class') || 'closed'
    this.menuToggleClass = this.data.get('class') || 'hidden'
  }

  toggle() {
    this.buttonTarget.classList.toggle(this.buttonToggleClass)
    this.menuTarget.classList.toggle(this.menuToggleClass)
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.menuToggleClass)&&
      !this.menuTarget.classList.contains(this.menuToggleClass)
    ) {
      this.menuTarget.classList.add(this.menuToggleClass)
      this.buttonTarget.classList.add(this.buttonToggleClass)
    }
  }
}