import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'modal', 'title', 'description' ];

  open(event) {
    event.stopPropagation();
    document.body.classList.add('modal-open');
    this.fetchCloud();
    this.modalTarget.classList.add('show');
  }

  close() {
    document.body.classList.remove('modal-open');
    this.modalTarget.classList.remove('show');
  }

  async fetchCloud() {
    await fetch(`/experfy-talentclouds/${this.data.get('id')}`)
      .then(response => response.text())
      .then(html => {
        const cloud = JSON.parse(html).data.attributes
        this.titleTarget.innerHTML = cloud.title
        this.descriptionTarget.innerHTML = cloud.description
      });
  }
}  