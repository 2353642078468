import { Controller } from "stimulus"
const Choices = require('choices.js')

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTarget.choices = new Choices(this.inputTarget, {
      removeItemButton: true,
      shouldSort: false,
      duplicateItemsAllowed: false,
      addItems: false
    });
  }

  disconnect() {
    this.inputTarget.choices.destroy();
  }
}
