import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
    "jobPage",
    "title",
    "info",
    "questions",
    "applied",
    "linkedinLink",
    "linkedinTrigger",
    "resume",
    "resumeTrigger",
    "originalEmail",
    "email"
  ];

  // helpers
  updateVisibility(targets, value) {
    targets.forEach(target => {
      target.style.display = value;
    });
  }
  switchTriggerClass(newActive, oldActive) {
    if (!newActive.classList.contains("active")) {
      newActive.classList.add("active");
    }
    oldActive.classList.remove("active");
  }

  // button actions
  showModal() {
    this.jobPageTarget.classList.add("modal-open");
    this.updateVisibility([this.modalTarget], "block");
    this.updateVisibility(this.infoTargets, "block");
  }
  continueToQuestions() {
    this.updateVisibility(this.infoTargets, "none");
    this.updateVisibility(this.questionsTargets, "block");
  }
  backToInfo() {
    this.updateVisibility(this.questionsTargets, "none");
    this.updateVisibility(this.infoTargets, "block");
  }
  sendApplication() {
    this.updateVisibility(this.infoTargets, "none");
    this.updateVisibility(this.questionsTargets, "none");
    this.updateVisibility(this.appliedTargets, "block");
  }
  closeEverything() {
    this.jobPageTarget.classList.remove("modal-open");
    this.switchTriggerClass(
      this.resumeTriggerTarget,
      this.linkedinTriggerTarget
    );
    this.updateVisibility(this.infoTargets, "none");
    this.updateVisibility(this.questionsTargets, "none");
    this.updateVisibility(this.appliedTargets, "none");
    this.updateVisibility([this.modalTarget], "none");
  }
  displayResume() {
    this.switchTriggerClass(
      this.resumeTriggerTarget,
      this.linkedinTriggerTarget
    );
    this.updateVisibility([this.linkedinLinkTarget], "none");
    this.updateVisibility([this.resumeTarget], "block");
  }
  displayLink() {
    this.switchTriggerClass(
      this.linkedinTriggerTarget,
      this.resumeTriggerTarget
    );
    this.updateVisibility([this.resumeTarget], "none");
    this.updateVisibility([this.linkedinLinkTarget], "block");
  }
}
