import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'link', 'modal', 'iframe' ];

  open(event) {
    document.body.classList.add('modal-open');
    this.modalTarget.classList.add('show');
    this.iframeTarget.src = event.currentTarget.dataset.url;
  }

  close(event) {
    event.preventDefault()
    this.modalTarget.classList.add('hide')
    this.modalTarget.classList.remove('show')
    document.body.classList.remove('modal-open')
    setTimeout(() => {  
      this.modalTarget.classList.remove('hide')
      this.iframeTarget.src = '';
    }, 499)
  }
}  